import React from "react"
import RealmPanelAccordion from "."

export default function StakeUnstakeSection() {
  return (
    <RealmPanelAccordion title="Guardians (coming soon)" isDisabled>
      TODO
    </RealmPanelAccordion>
  )
}
